import axios, { AxiosRequestConfig } from 'axios'
import { ITimecard } from '../interface/ISelectedDate'
import { store } from './Store'
import { setSelectedDate } from './slice/SelectedDateSlice'
import { setLoadingDate } from './slice/LoadingDateSlice'

export const UpdateStoreHelper = (date: string, token: string) => {
    let newSelectedDate: ITimecard = {
        header: undefined,
        lines: [],
    }
    const config: AxiosRequestConfig = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data: {},
        params: {
            date: date,
            /*code: process.env.REACT_APP_BACKEND_URL*/
        },
    }
    store.dispatch(setLoadingDate(true))
    axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/Timecard`, config)
        .then((response) => {
            newSelectedDate = response.data
            store.dispatch(setSelectedDate(newSelectedDate))
            store.dispatch(setLoadingDate(false))
        })
        .catch((error) => {
            console.log(error)
            store.dispatch(setLoadingDate(false))
        })
}
