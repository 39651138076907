import axios, { AxiosRequestConfig } from 'axios'
import { store } from './Store'
import { setLoadingDate } from './slice/LoadingDateSlice'
import { setSelectedJournal } from './slice/JournalSlice'
import { IJournal } from '../interface/IJournal'

export const UpdateStoreHelperJournal = (date: string, token: string) => {
    let newSelectedDate: IJournal = {
        header: undefined,
        lines: [],
    }
    const config: AxiosRequestConfig = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data: {},
        params: {
            date: date,
            /*code: process.env.REACT_APP_BACKEND_URL*/
        },
    }
    store.dispatch(setLoadingDate(true))
    axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/Journal`, config)
        .then((response) => {
            newSelectedDate = response.data
            store.dispatch(setSelectedJournal(newSelectedDate))
            store.dispatch(setLoadingDate(false))
        })
        .catch((error) => {
            console.log(error)
            store.dispatch(setLoadingDate(false))
        })
}
