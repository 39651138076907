import { IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import ZDELine from './ZDELine'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useTranslation } from 'react-i18next'
import { ITimecard, TimecardLine } from '../interface/ISelectedDate'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedDate } from '../source/slice/SelectedDateSlice'
import { IUser } from '../interface/IUser'
import { EmptyGuid } from '../config/Values'

const ZDELines = () => {
    const selectedDate: ITimecard = useSelector((state: any) => state.selectedDate?.value)
    const lines: TimecardLine[] | undefined = selectedDate?.lines
    const user: IUser = useSelector((state: any) => state.user?.value)
    const loadingDate: boolean = useSelector((state: any) => state.loadingDate?.value)

    const dispatch = useDispatch()

    const { t } = useTranslation()

    const filteredLines = selectedDate?.lines?.filter((line) => !line.delete)

    let fromTime: string | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_timeandattendancejobid === `${process.env.REACT_APP_JID_START}` && line.mserp_oncalltimeandattendancejobid !== 'RB' && line.mserp_oncalltimeandattendancejobid !== 'SE')[0]?.mserp_startdatetime
    let toTime: string | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_timeandattendancejobid === `${process.env.REACT_APP_JID_STOP}` && line.mserp_oncalltimeandattendancejobid !== 'RB' && line.mserp_oncalltimeandattendancejobid !== 'SE')[0]?.mserp_stopdatetime
    let travelTime: number = selectedDate?.header?.mserp_mbquantitytravel ? (isNaN(selectedDate?.header?.mserp_mbquantitytravel) ? 0 : selectedDate?.header?.mserp_mbquantitytravel) : 0
    let breaks: TimecardLine[] | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_timeandattendancejobid === `${process.env.REACT_APP_JID_BREAK}` && line.delete === false)
    let linesWithSpecialOperation: TimecardLine[] | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_oncalltimeandattendancejobid !== undefined && line.mserp_oncalltimeandattendancejobid === 'SE')
    let hasSpecialOperation: boolean = linesWithSpecialOperation ? (linesWithSpecialOperation.length > 0 ? true : false) : false

    const getBreaksTime = () => {
        var breaksTime: number = 0.0
        breaks?.forEach((breakItem) => {
            //if (breakItem.mserp_stopdatetime && breakItem.mserp_startdatetime) breaksTime += (new Date(breakItem.mserp_stopdatetime).getTime() - new Date(breakItem.mserp_startdatetime).getTime()) / 1000 / 60 / 60
            if (breakItem.mserp_calculatedtimeseconds) breaksTime += breakItem.mserp_calculatedtimeseconds / 60 / 60
        })
        return breaksTime
    }

    let workingTime: number = 0.0
    if (fromTime !== undefined && toTime !== undefined) {
        workingTime = Number(((new Date(toTime).getTime() - new Date(fromTime).getTime()) / 1000 / 60 / 60 - getBreaksTime()).toFixed(2))
    }

    let workingtimeAccordingArbZG: number = 0.0
    if (fromTime !== undefined && toTime !== undefined) {
        workingtimeAccordingArbZG = Number(((new Date(toTime).getTime() - new Date(fromTime).getTime()) / 1000 / 60 / 60 - getBreaksTime() - travelTime).toFixed(2))
    }

    const sumMenge : number =
        Number(selectedDate?.lines?.reduce((sum, line) => {
            if (!line.mserp_calculatedtimeseconds || line.mserp_timeandattendancejobid === process.env.REACT_APP_JID_BREAK || line.mserp_oncalltimeandattendancejobid === 'SE' || line.delete === true) return sum
            /* const start = new Date(line.mserp_startdatetime)
            const stop = new Date(line.mserp_stopdatetime)
            const duration = (stop.getTime() - start.getTime()) / (1000 * 60 * 60) */
            const dur = line.mserp_calculatedtimeseconds / 60 / 60
            return sum + dur
        }, 0).toFixed(2)) || 0

    const remainingTime = workingTime - sumMenge
    const workingTimeDisplay = isNaN(workingTime) ? 0 : workingTime
    const remainingTimeDisplay = isNaN(remainingTime) ? 0 : remainingTime

    const addNewLine = () => {
        var newEmptyLine = {
            mserp_timeprofiledate: selectedDate.header?.mserp_timeprofiledate,
            mserp_workerpersonnelnumber: selectedDate.header?.mserp_workerpersonnelnumber,
            mserp_dataareaid: user.resource.mserp_resourcecompanyid,
            mserp_mbelectronictimecardlineentityid: EmptyGuid,
            mserp_startdatetime: '',
            mserp_stopdatetime: '',
            mserp_linenumber: 0,
            mserp_projectid: '',
            mserp_projectcategoryid: '',
            mserp_timeandattendancejobid: '',
            mserp_indirectactivitycategoryid: '',
            mserp_calculatedtimeseconds: 0,
            mserp_purchid: '',
            mserp_purchlinenumber: 0,
            mserp_oncalltimeandattendancejobid: '',
            mserp_mboncallapplicationid: '',
            delete: false,
        }

        dispatch(
            setSelectedDate({
                ...selectedDate,
                lines: [...(selectedDate.lines || []), newEmptyLine],
            })
        )
    }

    var formDisabled = selectedDate?.header?.isTransferred || selectedDate?.header?.calculated || selectedDate?.dayInformation?.mserp_transferred // TODO check whether date is outside of permitted recording period

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ ...(hasSpecialOperation && { minWidth: 900 }), ...(!hasSpecialOperation && { minWidth: 700 }), textOverflow: 'ellipsis', tableLayout: 'fixed' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: { xs: 84, md: 100, lg: 84, xl: 100 }, textAlign: 'center', padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>
                                {!formDisabled ? (
                                    <Tooltip title={t('zdelines.add')}>
                                        <IconButton size="small" onClick={addNewLine}>
                                            <AddCircleIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <IconButton size="small" onClick={addNewLine} disabled={true}>
                                        <AddCircleIcon />
                                    </IconButton>
                                )}
                            </TableCell>
                            <TableCell sx={{ width: '100%', padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>{t('zdelines.project')}</TableCell>
                            <TableCell sx={{ width: '100%', padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>{t('zdelines.activity')}</TableCell>
                            {user?.worker?.mserp_employmentcategory === 'ANÜ' ? <TableCell sx={{ width: '100%', padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>{t('zdelines.order')}</TableCell> : <></>}
                            {hasSpecialOperation ? (
                                <>
                                    <TableCell sx={{ width: { xs: 136, md: 152, lg: 136, xl: 152 }, padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>{t('zdelines.amount')}</TableCell>
                                    <TableCell sx={{ width: { xs: 136, md: 152, lg: 136, xl: 152 }, padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>{t('zdelines.from')}</TableCell>
                                    <TableCell sx={{ width: { xs: 144, md: 152, lg: 144, xl: 152 }, padding: { xs: '16px 16px 16px 8px', md: '16px', lg: '16px 16px 16px 8px', xl: '16px' } }}>{t('zdelines.to')}</TableCell>
                                </>
                            ) : (
                                <TableCell sx={{ width: { xs: 144, md: 152, lg: 144, xl: 152 }, padding: { xs: '16px 16px 16px 8px', md: '16px', lg: '16px 16px 16px 8px', xl: '16px' } }}>{t('zdelines.amount')}</TableCell>
                            )}
                            {/*<TableCell>{t('zdelines.from')}</TableCell>
                            <TableCell>{t('zdelines.to')}</TableCell>
                            <TableCell>{t('zdelines.amount')}</TableCell>*/}
                            {/* <TableCell>{t('zdelines.comment')}</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lines?.map((row, i) => (
                            <ZDELine row={row} key={i} id={i} hasSpecialOperation={hasSpecialOperation} />
                        ))}

                        {/* ?.filter((row: TimecardLine) => row.mserp_indirectactivitycategoryid === '') */}
                    </TableBody>
                </Table>
                {!loadingDate ? (
                    <Typography
                        style={{ textAlign: 'end', textWrap: 'balance' }}
                        color={remainingTimeDisplay !== 0 ? 'error' : 'success'}
                        mb={2}
                        mx={2}
                        display={formDisabled ? 'none' : ''}
                    >
                        {/* <Typography style={{ textAlign: 'end', color: add(remainingTimeDisplay) === workingTimeDisplay ? 'success' : 'error' }} mb={2} mx={2}>  */}
                        {t('zdeheader.hoursdistribute', {
                            from: remainingTimeDisplay.toFixed(2).replace('.', ','),
                            to: workingTimeDisplay.toFixed(2).replace('.', ','),
                        })}
                    </Typography>
                ) : (
                    <Skeleton variant="text" width="20%" style={{float: 'right', marginBottom: '16px', marginLeft: '16px', marginRight: '16px'}} />
                )}
            </TableContainer>
        </>
    )
}

export default ZDELines
