import { Tooltip, Menu, MenuItem, ListItemIcon, Stack, Divider, ToggleButtonGroup, ToggleButton, Chip, Modal, Link } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import AccountCircle from '@mui/icons-material/AccountCircle'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import ChatIcon from '@mui/icons-material/Chat'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IUser } from '../interface/IUser'
import { DarkMode, Language, LightMode, Logout } from '@mui/icons-material'
import { setTheme } from '../source/slice/ThemeSlice'
import { useMsal } from '@azure/msal-react'
import mbtiLogo from '../img/mbti-logo.png'
import logo from '../img/logo.png'
import { WorkdayIcon } from '../img/workday'
import MailIcon from '@mui/icons-material/Mail'

function Navigation() {
    //const theme = useTheme()
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const user: IUser = useSelector((state: any) => state.user?.value)
    const theme = useSelector((state: any) => state.theme?.value)
    const { instance } = useMsal()

    const [supportModalOpen, setSupportModalOpen] = useState<boolean>(false)
    const handleModalClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setSupportModalOpen(true)
    }
    const handleModalClose = () => {
        setSupportModalOpen(false)
    }

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng)
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const navMenuOpen = Boolean(anchorEl)
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const changeTheme = (color: string) => {
        dispatch(setTheme(color))
    }

    const logout = async () => {
        await instance.logoutRedirect()
        await instance.logoutPopup()
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="static"
                sx={(theme) => ({
                    backgroundColor: theme.palette.common.black,
                    backgroundImage: 'none',
                    boxShadow: 'none',
                })}
            >
                <Toolbar style={{ gap: 10 }}>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <img src={mbtiLogo} alt="MBTI-Logo" style={{ height: 50 }} />
                    </Box>

                    <img src={logo} alt="Logo" style={{ width: 32, height: 32 }} />

                    <Typography variant="h5" component="div" sx={{ fontFamily: 'MB Corpo A Title', flexGrow: 1 }}>
                        Time Tracking
                    </Typography>
                    <Chip label="Confidential" color="error" variant="outlined" />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Tooltip title={t('navigation.workday')}>
                            <Link href="https://wd3.myworkday.com/mercedesbenztechinnovation/d/home.htmld" underline="none" target="_blank" rel="noopener" color="inherit">
                                <IconButton size="large" color="inherit">
                                    <Badge>
                                        <WorkdayIcon />
                                    </Badge>
                                </IconButton>
                            </Link>
                        </Tooltip>

                        <Tooltip title={t('navigation.helpcenter')}>
                            <Link href="https://corpdir.sharepoint.com/sites/mbti-apps-and-tools/SitePages/Navision.aspx#hilfeartikel" underline="none" target="_blank" rel="noopener" color="inherit">
                                <IconButton size="large" color="inherit">
                                    <Badge>
                                        <QuestionMarkIcon />
                                    </Badge>
                                </IconButton>
                            </Link>
                        </Tooltip>

                        <Tooltip title={t('navigation.support.title')}>
                            <IconButton size="large" color="inherit" onClick={handleModalClick}>
                                <Badge>
                                    <ChatIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Modal open={supportModalOpen} onClose={handleModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 'calc(100% - 32px)',
                                    maxWidth: 800,
                                    height: 'calc(100% - 32px)',
                                    maxHeight: 'fit-content',
                                    overflow: 'auto',
                                    // textWrap: 'balance',
                                    bgcolor: 'background.paper',
                                    border: 0,
                                    borderRadius: 1,
                                    boxShadow: 24,
                                    p: {
                                        xs: 2,
                                        sm: 4,
                                    },
                                }}
                            >
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {t('navigation.support.title')}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    {t('navigation.support.description')}
                                </Typography>
                                <Typography sx={{ mt: 4 }}>{t('navigation.support.hrHeader')}</Typography>
                                <Typography sx={{ mt: 1 }}>
                                    <Stack alignItems="center" direction="row" gap={2}>
                                        <MailIcon color="inherit" fontSize="small" />
                                        <Link href="mailto:mbti-hr-services@mercedes-benz.com" target="_blank" rel="noopener" underline="hover">
                                            {t('navigation.support.hrLink')}
                                        </Link>
                                    </Stack>
                                </Typography>
                                <Typography sx={{ mt: 4 }}>{t('navigation.support.pfcHeader')}</Typography>
                                <Typography sx={{ mt: 1 }}>
                                    <Stack alignItems="center" direction="row" gap={2}>
                                        <MailIcon color="inherit" fontSize="small" />
                                        <Link href="mailto:mbti-pfc@mercedes-benz.com" target="_blank" rel="noopener" underline="hover">
                                            {t('navigation.support.pfcLink')}
                                        </Link>
                                    </Stack>
                                </Typography>
                                <Typography sx={{ mt: 4 }}>{t('navigation.support.controllingHeader')}</Typography>
                                <Typography sx={{ mt: 1 }}>
                                    <Stack alignItems="center" direction="row" gap={2}>
                                        <MailIcon color="inherit" fontSize="small" />
                                        <Link href="mailto:mbti-controlling@mercedes-benz.com" target="_blank" rel="noopener" underline="hover">
                                            {t('navigation.support.controllingLink')}
                                        </Link>
                                    </Stack>
                                </Typography>
                                <Typography sx={{ mt: 4 }}>{t('navigation.support.foteamHeader')}</Typography>
                                <Typography sx={{ mt: 1 }}>
                                    <Stack alignItems="center" direction="row" gap={2}>
                                        <MailIcon color="inherit" fontSize="small" />
                                        <Link href="mailto:mbti-navteam@mercedes-benz.com" target="_blank" rel="noopener" underline="hover">
                                            {t('navigation.support.foteamLink')}
                                        </Link>
                                    </Stack>
                                </Typography>{' '}
                            </Box>
                        </Modal>
                        <IconButton size="large" edge="end" aria-haspopup="true" color="inherit" aria-controls={navMenuOpen ? 'basic-menu' : undefined} aria-expanded={navMenuOpen ? 'true' : undefined} onClick={handleMenuClick}>
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={navMenuOpen}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <Stack direction="row" alignItems="center" gap={1} px={2} paddingTop={1}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    {user?.worker?.mserp_name}
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={1} px={2}>
                                <Typography variant="body2" sx={{ color: 'grey' }}>
                                    {user?.resource?.mserp_resourceid}
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={1} px={2} paddingBottom={1}>
                                <Typography variant="body2" sx={{ color: 'grey' }}>
                                    {user.userid}
                                </Typography>
                            </Stack>
                            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
                            <Stack alignItems="center" px={2} py={1}>
                                <ToggleButtonGroup exclusive onChange={(e, val: string) => changeLanguage(val)} aria-label="text alignment">
                                    <ToggleButton value="de" aria-label="left aligned" selected={i18n.language === 'de'} style={{ width: '108px', gap: '6px' }}>
                                        <Language /> Deutsch
                                    </ToggleButton>
                                    <ToggleButton value="en" aria-label="right aligned" selected={i18n.language === 'en'} style={{ width: '108px', gap: '6px' }}>
                                        <Language /> English
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                            <Stack alignItems="center" px={2} py={1}>
                                <ToggleButtonGroup exclusive onChange={(e, val: string) => changeTheme(val)} aria-label="text 2">
                                    <ToggleButton value="light" aria-label="right aligned" selected={theme === 'light'} style={{ width: '150px', gap: '6px' }}>
                                        <LightMode /> {t('navigation.lightMode')}
                                    </ToggleButton>
                                    <ToggleButton value="dark" aria-label="left aligned" selected={theme === 'dark'} style={{ width: '150px', gap: '6px' }}>
                                        <DarkMode /> {t('navigation.darkMode')}
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
                            <MenuItem onClick={logout}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                {t('navigation.logout')}
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Navigation
