import { Box, CircularProgress, Link, useTheme } from '@mui/material'
import Calendar from './Calendar'
import ZDEHeader from './ZDEHeader'
import JournalHeader from './Journal/JournalHeader'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { de } from 'date-fns/locale/de'
import { enUS } from 'date-fns/locale/en-US'
import { ITimecard } from '../interface/ISelectedDate'
import { IUser } from '../interface/IUser'
import { useSelector } from 'react-redux'
import workdayLogo from '../img/workday-logo.png'

const Dashboard = () => {
    const { t, i18n } = useTranslation()
    const theme = useTheme()
    const selectedDate: ITimecard = useSelector((state: any) => state.selectedDate?.value)
    const user: IUser = useSelector((state: any) => state.user?.value)

    const isInternalOrANU: boolean =
        user?.worker?.mserp_employmentcategory &&
        (user?.worker?.mserp_employmentcategory === process.env.REACT_APP_USER_INTERNAL || user?.worker?.mserp_employmentcategory === process.env.REACT_APP_USER_STUDENT || user?.worker?.mserp_employmentcategory === 'ANÜ')
            ? true
            : false
    const calendarBlocked: boolean = (selectedDate?.header?.mserp_timeprofileid && selectedDate?.header?.mserp_timeprofileid === 'BLOCK') || (selectedDate?.header?.specialDayId && selectedDate?.header?.specialDayId === 'REDUCE') ? true : false

    const formatDate = () => {
        return i18n.language === 'de'
            ? format(new Date(selectedDate?.header?.mserp_timeprofiledate ?? new Date().toISOString()), 'EEEE, dd.MM.yyyy', {
                  locale: de,
              })
            : format(new Date(selectedDate?.header?.mserp_timeprofiledate ?? new Date().toISOString()), 'EEEE, MM/dd/yyyy', {
                  locale: enUS,
              })
    }

    const getHeader = () => {
        if (calendarBlocked) {
            return (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        minHeight: { xs: isInternalOrANU ? 'calc(100vh - 606px)' : 'calc(100vh - 574px)', sm: isInternalOrANU ? 'calc(100vh - 614px)' : 'calc(100vh - 582px)', md: 'calc(100vh - 574px)', lg: 'calc(100vh - 118px)' },
                        textWrap: 'balance',
                        gap: { xs: '16px', md: '32px' },
                        padding: { xs: '32px', sm: '32px 64px', lg: '32px 128px' },
                    }}
                    width="100%"
                    textAlign="center"
                    flex="1 1 auto"
                >
                    <img src={workdayLogo} alt="Workday-Logo" style={{ height: 50 }} />
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" textAlign="center" gap={1}>
                        {t('calendar.blocked', {
                            date: formatDate(),
                        })}
                        <Link href="https://wd3.myworkday.com/mercedesbenztechinnovation/d/home.htmld" target="_blank" rel="noopener" underline="hover" mt={1}>
                            {t('calendar.workdayLink')}
                        </Link>
                    </Box>
                </Box>
            )
        }

        if(selectedDate.dayInformation?.mserp_transferred && !selectedDate.header?.isTransferred){
            return (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        minHeight: { xs: isInternalOrANU ? 'calc(100vh - 606px)' : 'calc(100vh - 574px)', sm: isInternalOrANU ? 'calc(100vh - 614px)' : 'calc(100vh - 582px)', md: 'calc(100vh - 574px)', lg: 'calc(100vh - 118px)' },
                        textWrap: 'balance',
                        gap: { xs: '16px', md: '32px' },
                        padding: { xs: '32px', sm: '32px 64px', lg: '32px 128px' },
                    }}
                    width="100%"
                    textAlign="center"
                    flex="1 1 auto"
                >
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" textAlign="center" gap={1}>
                        {t('calendar.blockednotrecorded', {
                            date: formatDate(),
                        })}
                        <br/>
                        <br/>
                        {t('calendar.blockednotrecordedinfo1')}
                        <br/>
                        <br/>
                        {t('calendar.blockednotrecordedinfo2')}
                    </Box>
                </Box>
            )
        }

        return <Box padding={4}>{user?.worker?.mserp_usetimecard ? <ZDEHeader /> : <JournalHeader />}</Box>
    }

    return (
        <Box display="flex" sx={{ flexDirection: { xs: 'column', lg: 'row' }, alignItems: { xs: 'center', lg: 'flex-start' }, justifyContent: { xs: 'flex-start', lg: 'center' } }}>
            <Box justifyContent="center" display="flex" bgcolor={theme.palette.background.default} sx={{ width: { xs: 'unset', lg: 400 }, flexGrow: 0, flexShrink: 0 }}>
                <Box paddingTop={2} paddingBottom={4}>
                    <Calendar />
                </Box>
            </Box>
            <Box
                bgcolor={theme.palette.background.paper}
                sx={{ minHeight: { xs: 'calc(100vh - 512px)', sm: 'calc(100vh - 520px)', lg: 'calc(100vh - 64px)' }, width: { xs: '100%', lg: 'calc(100% - 400px)' }, pb: isInternalOrANU ? { xs: '94px', md: '54px' } : { xs: '62px', md: '54px' } }}
            >
                {user.worker ? (
                    getHeader()
                ) : (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: { xs: isInternalOrANU ? 'calc(100vh - 606px)' : 'calc(100vh - 574px)', sm: isInternalOrANU ? 'calc(100vh - 614px)' : 'calc(100vh - 582px)', md: 'calc(100vh - 574px)', lg: 'calc(100vh - 118px)' } }}
                        width="100%"
                        flex="1 1 auto"
                    >
                        <CircularProgress color="inherit" />
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default Dashboard
